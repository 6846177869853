<template>
  <b-card bg-variant="light"
          header="Feedbacks"
          header-bg-variant="dark"
          header-text-variant="white">

    <feedback_list :elevated="false"
                   :load_data_url="url"
                   :prop_feedback_fields="feedback_fields"
                   :prop_fields="fields"></feedback_list>
  </b-card>
</template>

<script>
  import feedback_list from '@/components/proposal/feedbacks/feedback_list';
  import { ordinalNumber } from '@/helpers/commonTableColumns';

  export default {
    name: 'feedback_dashboard',
    components: { feedback_list },
    data() {
      return {
        url: '/documents/feedbacks/me',
        fields: [
          ordinalNumber,
          {
            key: 'document_specification.document_id',
            label: 'ID',
            thStyle: { width: '6%' },
          },
          {
            key: 'title',
            label: 'Title',
          },
          {
            key: 'author',
            label: 'Author',
            class: 'custom-break-col-md',
            thStyle: { width: '10%' },
          },
          {
            key: 'states.date',
            label: 'Proposal submission date',
            thStyle: { width: '9%' },
          },
          { key: 'actions' },
        ],
        feedback_fields: [
          {
            key: 'author',
            label: 'Author',
          },
          {
            key: 'states.date',
            label: 'Submitted at',
          },
          { key: 'actions' },
        ],
      };
    },
  };
</script>

<style scoped>
  .card {
    margin: 2rem;
  }
</style>
